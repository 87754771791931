
.rc-home[data-v-5985b953] {
  position: relative;
  z-index: 1;
}
.rc-hidden[data-v-5985b953] {
  display: none;
}
@media (min-width: 640px) {
.rc-hidden[data-v-5985b953] {
    display: block;
}
}
